import { Component, OnInit } from '@angular/core';
import { LatestNewsPojo } from '../pojo/latest-news.pojo';
import { LatestNewsService } from '../services/latest-news.service';
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    lang: string;
    latestNewsList: LatestNewsPojo[] = [];


    constructor(private latestNewsService: LatestNewsService) { }

    ngOnInit() {

        // this.getAllNews();

        this.lang = sessionStorage.getItem('language');
        if (this.lang == 'ENGLISH') {
            this.lFlag = true;
        }
        else {
            this.lFlag = false;
        }

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

    }

    getAllNews() {
        this.latestNewsService.getaAllPost().subscribe(response => {
            this.latestNewsList = response;
        }, error => {
            bootbox.alert({ message: 'Get All latest news Error' + error.status, className: 'text-danger', closeButton: false });
        })
    }
    

    lFlag: boolean = false;

}
