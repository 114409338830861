import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    lang: string;
    
    lFlag: boolean = true;

  constructor() { }

  ngOnInit() {
      this.lang = sessionStorage.getItem('language');
      if(this.lang=='ENGLISH'){
          this.lFlag = true;       
          }
      else{
          this.lFlag = false;  
      }

  }

  callNow() {
    window.location.href = 'tel:04364287429'; // your phone number
  }
  
  openWhatsApp() {
    const phoneNumber = '+918925077235'; // your WhatsApp number with country code
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  }
  

}
