export class StaticVariable {

   static porturl = 'http://192.168.29.196:8086/TicketBookingApi/';

   // static porturl = 'http://148.66.133.79:8080/TirukkadaiyurAbiramiTicketBookingApi/';


   static status_1 = 'INITIAL';
   static status_2 = 'SUCCESS';
   static status_3 = 'FAILED';
   static status_4 = 'PENDING';
   static smsEncryptOffset = 25;
   static technicalSupport = ', ';
   static mail1 = 'thirukkadaiyurdevasthanam@gmail.com';
   static mail2 = '';
   static landLine = '+91- 4364 - 287 429';
   static tollFree = '';
   static pgKey1 = '';
   static pgKey2 = '';
   static pgSalt1 = '';
   static pgSalt2 = '';
   static language: string = "ENGLISH";
}


